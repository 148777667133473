// core version + navigation, pagination modules:
import Swiper from './swiper.js';

window.addEventListener('load', () => {

    const swiperElements = document.querySelectorAll('.custom__swiper');

    let counter = 0;
    swiperElements.forEach((swiperEl) => {

        swiperEl.classList.add(`swiper__${counter}`);

        const slidesPerView = swiperEl.dataset.slides;
        const pagination = swiperEl.dataset.pagination;
        const navigation = swiperEl.dataset.navigation;
        const space = swiperEl.dataset.space;
        const swiperName = `.swiper__${counter}`;
        const autoHeight = swiperEl.dataset.autoHeight;
        const autoplay = swiperEl.dataset.autoPlay;

        let autoplayBool = false;
        if (autoplay === 'true') {
            autoplayBool = true
        }

        let autoHeightBool = false;
        if (autoHeight === 'true') {
            autoHeightBool = true
        }

        let navigationObj = {};
        if (navigation === 'true') {
            navigationObj = {
                nextEl: `.swiper-button-next-${counter}`,
                prevEl: `.swiper-button-prev-${counter}`,
            }

            const navigationElPrev = document.createElement('div');
            navigationElPrev.classList.add('swiper-button-prev');
            navigationElPrev.classList.add(`swiper-button-prev-${counter}`);

            const navigationElNext = document.createElement('div');
            navigationElNext.classList.add('swiper-button-next');
            navigationElNext.classList.add(`swiper-button-next-${counter}`);

            swiperEl.parentNode.insertBefore(navigationElPrev, swiperEl.nextSibling);
            swiperEl.parentNode.insertBefore(navigationElNext, swiperEl.nextSibling);
        }

        let paginationObj = {};
        if (pagination === 'true') {
            paginationObj = {
                el: `.swiper-pagination-${counter}`,
                clickable: true,
            }

            const paginationEl = document.createElement('div');
            paginationEl.classList.add(`swiper-pagination-${counter}`);
            paginationEl.classList.add('swiper-pagination-all');
            swiperEl.parentNode.insertBefore(paginationEl, swiperEl.nextSibling);
        }

        let slides600 = 2;
        let spaceSmall = 10;
        if (slidesPerView === "1") {
            slides600 = 1
            spaceSmall = parseInt(space)
        }

        let slides900 = 3;
        if (slidesPerView === "1") {
            slides900 = 1
        }

        const swiper = new Swiper(swiperName, {
            direction: 'horizontal',
            loop: true,

            // If we need pagination
            speed: 800,
            parallax: true,
            spaceBetween: parseInt(space),

            // Navigation arrows
            navigation: navigationObj,
            pagination: paginationObj,
            slidesPerView: slidesPerView,
            autoHeight: autoHeightBool,
            autoplay: autoplayBool,

            breakpoints: {
                120: {
                    slidesPerView: 1,
                    spaceBetween: spaceSmall
                },

                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: spaceSmall
                },
                // when window width is >= 640px
                600: {
                    slidesPerView: slides600,
                    spaceBetween: parseInt(space)
                },

                900: {
                    slidesPerView: slides900,
                    spaceBetween: parseInt(space)
                },

                1200: {
                    slidesPerView: slidesPerView,
                    spaceBetween: parseInt(space)
                }

            }

        });

        counter++;
    })


}, false);


document.addEventListener('DOMContentLoaded', () => {

    let contentMenuItems = document.getElementsByClassName('content-menu-with-content--menu-items');
    let contentMenuContents = document.getElementsByClassName('content-menu-with-content--content-overlay');
    let contentMenuMedias = document.getElementsByClassName('content-menu-with-content--media-area');
    for (let item of contentMenuItems) {

        item.addEventListener('click', () => {
            for (let inactive of contentMenuItems) {
                inactive.classList = "content-menu-with-content--menu-items"
            }
            item.classList = "content-menu-with-content--menu-items content-menu-with-content--menu-items__active";
            let numberOfContentElement = item.dataset.itemNumber;

            for (let inactiveContent of contentMenuContents) {
                inactiveContent.classList = "content-menu-with-content--content-overlay";
            }

            for (let inactiveMedia of contentMenuMedias) {
                if (inactiveMedia.dataset.mobile === "true") {
                    inactiveMedia.classList = "content-menu-with-content--media-area content-menu-with-content--media-mobile";
                } else {
                    inactiveMedia.classList = "content-menu-with-content--media-area";
                }
            }

            if (isNotNull(numberOfContentElement)) {
                numberOfContentElement = parseInt(numberOfContentElement);
                let desktopIndex = numberOfContentElement+3;

                contentMenuContents[numberOfContentElement].classList = "content-menu-with-content--content-overlay content-menu-with-content--content-overlay__active";
                contentMenuMedias[numberOfContentElement].classList.toggle('content-menu-with-content--media-area__active--mobile');
                contentMenuMedias[desktopIndex].classList.toggle('content-menu-with-content--media-area__active');
            }
        })
    }

    /** parallax instant */
    let parallaxInstantTop = document.querySelectorAll('.parallax-instant--top');
    let parallaxInstantBottom = document.querySelectorAll('.parallax-instant--bottom');

    activateParallax(parallaxInstantTop, 'instantTop');
    activateParallax(parallaxInstantBottom, 'instantBottom');

    /** headline */

    let headline = document.querySelector('.headline-animation');
    if (isNotNull(headline)) {
        let headlineText = headline.textContent;
        headline.textContent = "";
        let headlineSplit = headlineText.split("");

        for (let i = 0; i < headlineSplit.length; i++) {
            if (headlineSplit[i] === " ") {
                headline.innerHTML += "<span>" + "&nbsp" + "</span>";
                continue;
            }
            headline.innerHTML += "<span>" + headlineSplit[i] + "</span>";
        }

        let char = 0;
        let timer = setInterval(() => {
            const span = headline.querySelectorAll('span')[char];
            span.classList.add('fade');
            char++;
            if (char === headlineSplit.length) {
                complete();
                return;
            }
        }, 50);

        const complete = () => {
            clearInterval(timer);
            timer = null;
        }

    }

    let headLineButton = document.querySelector('.headline-button');
    if (isNotNull(headLineButton)) {
        headLineButton.addEventListener('click', () => {
            let headlineBackground = document.querySelector('.headline-background-text');
            if (isNotNull(headlineBackground)) {
                headlineBackground.classList.add('expand')
            }
        })
    }

    /** content-menu-with content */

    window.addEventListener('scroll', () => {
        let innerHeight = window.innerHeight;
        let changeBackground = document.querySelectorAll('.change-background-color');

        if (isNotNull(changeBackground)) {
            for (let el of changeBackground) {

                let distanceTop = el.getBoundingClientRect().top;

                if (distanceTop < innerHeight-200) {
                    el.style.backgroundColor = el.dataset.backgroundReal;
                }

                if (distanceTop > innerHeight-200) {
                    el.style.backgroundColor = el.dataset.backgroundShort;
                }
            }
        }

        /** parallax */
        let parallaxLefts = document.querySelectorAll('.parallax-left');
        let parallaxRights = document.querySelectorAll('.parallax-right');
        let parallaxBottoms = document.querySelectorAll('.parallax-bottom');
        let parallaxTops = document.querySelectorAll('.parallax-top');

        activateParallax(parallaxLefts, 'left');
        activateParallax(parallaxRights, 'right');
        activateParallax(parallaxBottoms, 'bottom');
        activateParallax(parallaxTops, 'top');

        /** header */
        stickyHeader();

    })

    /** form-submit **/

    const submit = document.querySelector('.form-submit');
    if (isNotNull(submit)) {
        submit.addEventListener('click', () => {
            const form = document.forms["main-form"]
            const requiredFields = form.querySelectorAll("[required]")

            let formHasError = false;

            requiredFields.forEach((field) => {
                switch (field.type) {
                    case 'text':
                        formHasError = field.value == null || field.value === ''
                        break;
                    case 'email':
                        formHasError = validateEmail(field.value) !== null
                        break;
                    case 'checkbox':
                        formHasError = field.checked === false
                        break;
                    default:

                }

            })

            if (!formHasError) {
                const modal = document.querySelector('.modal');
                if (isNotNull(modal)) {
                    modal.classList.add('modal-active');
                    document.body.style.position = 'fixed'
                }
            }

        })
    }


    /** job-list */

    let tags = document.querySelectorAll('.open-job-list--filter-item');
    let jobs = document.querySelectorAll('.open-job-list--list-job');
    let category = document.querySelector('.open-job-list--filter-item--filter-text');

    if (isNotNull(tags) && isNotNull(jobs) && isNotNull(category)) {
        let filterIdActive = category.dataset.categoryActive;
        if (filterIdActive !== "null") {
            for (let tagEl of tags) {
                if (tagEl.dataset.categoryId === filterIdActive) {
                    tagEl.classList.add('open-job-list--filter-item__active');
                }
            }
            for (let job of jobs) {
                if (job.dataset.categoryId !== filterIdActive) {
                    job.classList.toggle('open-job-list--job__inactive');
                }
            }
        }

        for (let tag of tags) {
            tag.addEventListener('click', () => {

                for (let tagEl of tags) {
                    tagEl.classList.remove('open-job-list--filter-item__active');
                }
                for (let job of jobs) {
                    job.classList.remove('open-job-list--job__inactive');
                }

                let categoryId = tag.dataset.categoryId;
                if (filterIdActive === "null") {
                    filterIdActive = categoryId;
                    tag.classList.add('open-job-list--filter-item__active');

                    for (let job of jobs) {
                        if (job.dataset.categoryId !== categoryId) {
                            job.classList.toggle('open-job-list--job__inactive');
                        }
                    }
                } else if (filterIdActive === categoryId) {
                    filterIdActive = "null";
                } else {
                    filterIdActive = categoryId;
                    tag.classList.add('open-job-list--filter-item__active');

                    for (let job of jobs) {
                        if (job.dataset.categoryId !== categoryId) {
                            job.classList.toggle('open-job-list--job__inactive');
                        }
                    }
                }

            })
        }
    }

    let arrowSliders = document.querySelectorAll('.category-slider-action');
    let sliderElements = document.querySelectorAll('.category-slider-single-container');
    let slider = document.getElementsByClassName('category-slider-container')[0];

    let sliderChildren = {};
    let active = [];
    let inActive = [];
    let allElements = [];

    createBubbles();

    let burgerMenu = document.querySelector('.hamburger');
    let headerMobile = document.querySelector('.nav-mobile');
    if (isNotNull(burgerMenu) && isNotNull(headerMobile)) {
        burgerMenu.addEventListener('click', () => {
            burgerMenu.classList.toggle('is-active');
            headerMobile.classList.toggle('nav-mobile-active')
        })
    }

    const heroVideoButton = document.querySelector('.headline-button--extra');
    const heroVideo = document.querySelector('.hero-main-video');
    const heroVideoOverlay = document.querySelector('.hero-video__overlay');
    if (isNotNull(heroVideoButton)) {

        const hero = document.querySelector('.hero');
        const play = document.querySelector('.hero-video-play-button');
        let showButton = true;
        if (window.innerWidth < 900) {
            showButton = false
        }

        if (isNotNull(hero) && isNotNull(play) && showButton) {

            hero.addEventListener('mouseleave', (e) => {
                play.classList.remove('hero-video-play-button--active')
            })

            hero.addEventListener('mouseenter', (e) => {

                hero.addEventListener('mousemove', (mousemoveevent) => {
                if (showButton) {

                    if (mousemoveevent.target.classList.contains('button') || mousemoveevent.target.classList.contains('button-text')) {
                            play.classList.remove('hero-video-play-button--active')
                            hero.style.cursor = '';
                    } else {
                        play.classList.add('hero-video-play-button--active')
                        const x = mousemoveevent.pageX;
                        const y = mousemoveevent.pageY;

                        play.style.left = `${x - 60}px`
                        play.style.top = `${y - 60}px`
                        hero.style.cursor = 'none';

                    }
                }


                })

                play.addEventListener('click', (mousemoveevent) => {
                    if (!(mousemoveevent.target.classList.contains('button') || mousemoveevent.target.classList.contains('button-text'))) {
                        heroVideoOverlay.classList.add('hero-video__overlay--active')
                        heroVideo.muted = false;
                        heroVideo.play();
                        play.classList.remove('hero-video-play-button--active')
                        hero.style.cursor = '';
                        showButton = false;

                        if (isNotNull(heroVideoOverlay)) {
                            heroVideoOverlay.addEventListener('click', (e) => {
                                if (!e.target.classList.contains('hero-main-video')) {
                                    heroVideoOverlay.classList.remove('hero-video__overlay--active')
                                    heroVideo.muted = true;
                                    heroVideo.pause();
                                }
                            })
                        }
                    }
                })
            })

        }

        heroVideoButton.addEventListener('click', () => {
            heroVideoOverlay.classList.add('hero-video__overlay--active')
            heroVideo.muted = false;
            heroVideo.play();
            play.classList.remove('hero-video-play-button--active')
            hero.style.cursor = '';
            showButton = false;

            if (isNotNull(heroVideoOverlay)) {
                heroVideoOverlay.addEventListener('click', (e) => {
                    if (!e.target.classList.contains('hero-main-video')) {
                        heroVideoOverlay.classList.remove('hero-video__overlay--active')
                        heroVideo.muted = true;
                        heroVideo.pause();
                    }
                })
            }

            /*
            if (heroVideo.requestFullscreen) {
                heroVideo.requestFullscreen();
            } else if (heroVideo.webkitRequestFullscreen) {
                heroVideo.webkitRequestFullscreen();
            } else if (heroVideo.webkitEnterFullscreen) {
                heroVideo.webkitEnterFullscreen();
            } else if (heroVideo.msRequestFullscreen) {
                heroVideo.msRequestFullscreen();
            }*/

            heroVideo.addEventListener("fullscreenchange", (event) => {
                /*
                if (!document.fullscreenElement) {
                    heroVideo.classList.remove('hero-main-video--play');
                    heroVideo.pause();
                    heroVideo.muted = true;
                    if (heroVideo.exitFullscreen) {
                        heroVideo.exitFullscreen();
                    } else if (heroVideo.webkitExitFullscreen) {
                        heroVideo.webkitExitFullscreen();
                    } else if (heroVideo.msExitFullscreen) {
                        heroVideo.msExitFullscreen();
                    }
                }*/

            });
        })
    }

    cookieAction();


})

const stickyHeader = () => {
    let header = document.querySelector('header');
    if (isNotNull(header)) {
        let position = header.offsetTop;
        if (window.pageYOffset > position) {
            header.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
        }
    }
}

const activateParallax = (elements, direction) => {

    if (isNotNull(elements)) {
        for (let el of elements) {
            let distanceTop = el.getBoundingClientRect().top;
            if (distanceTop < innerHeight) {
                el.style.transform = 'translate3d(0px, 0px, 0px)';
                el.style.opacity = '1';
            }

            if (distanceTop > innerHeight-100) {
                if (direction === 'left') {
                    //el.style.transform = 'translate3d(-' + el.getBoundingClientRect().top/10 +'px, 0px, 0px)';
                    el.style.transform = 'translate3d(-100px, 0px, 0px)';
                } else if(direction === 'right') {
                    //el.style.transform = 'translate3d(' + el.getBoundingClientRect().top/10 +'px, 0px, 0px)';
                    el.style.transform = 'translate3d(100px, 0px, 0px)';
                } else if(direction === 'top') {
                    el.style.transform = 'translate3d( 0px, 100px, 0px)';
                } else if(direction === 'instantTop') {
                    el.style.transform = 'translate3d( 0px, 100px, 0px)';
                } else if(direction === 'instantBottom') {
                    el.style.transform = 'translate3d( 0px, 100px, 0px)';
                } else {
                    el.style.transform = 'translate3d( 0px, 100px, 0px)';
                }
                el.style.opacity = '0';
            }
        }
    }
}

const isNotNull = (element) => {
    return typeof element !== 'undefined' && element !== null;
}

const scroll = (element, to, duration) => {
    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;

    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}

const cookieAction = () => {
    const cookieName = "berchtenbreiter-cookie";
    const cookie = getCookie(cookieName);
    if (!isNotNull(cookie)) {
        const cookieBanner = document.querySelector('.cookie');
        if (isNotNull(cookieBanner)) {
            cookieBanner.classList.add('cookie--active');
            const cookieAccept = document.querySelector('.cookie__accept');
            if (isNotNull(cookieAccept)) {
                cookieAccept.addEventListener('click', () => {
                    cookieBanner.classList.remove('cookie--active');
                    setCookie(cookieName, "true", 5);
                })
            }
        }
    }
}

const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getCookie = (name) => {
    let cookie = {};
    document.cookie.split(';').forEach(function(el) {
        let [k,v] = el.split('=');
        cookie[k.trim()] = v;
    })
    return cookie[name];
}

const validateEmail = (email) => {
    return email.match(
        /^\S+@\S+\.\S+$/
    );
};

const createBubbles = () => {


    let stylingBubblesBig = document.querySelectorAll('.team-bubble--styling--big');
    for (let el of stylingBubblesBig) {
        let random = Math.random() * (170 - 120) + 120;
        el.style.width = random + "px";
        el.style.height = random + "px";
        el.style.margin = Math.random() * (7 - 3) + 3 + "px";
    }

    let stylingBubbles = document.querySelectorAll('.team-bubble--styling');
    for (let el of stylingBubbles) {
        let random = Math.random() * (80 - 30) + 30;
        el.style.width = random + "px";
        el.style.height = random + "px";
        el.style.margin = Math.random() * (7 - 3) + 3 + "px";
        el.style.alignSelf = "center";
    }

    let stylingBubblesImg = document.querySelectorAll('.team-bubble--img');
    for (let el of stylingBubblesImg) {
        let random = Math.random() * (150 - 100) + 100;
        el.style.minWidth = random + "px";
        el.style.maxWidth = random + "px";
        el.style.height = random + "px";
        el.style.margin = Math.random() * (7 - 3) + 3 + "px";
    }

    let stylingBubblesImgSmall = document.querySelectorAll('.team-bubble--img--small');
    for (let el of stylingBubblesImgSmall) {
        let random = Math.random() * (120 - 50) + 50;
        el.style.minWidth = random + "px";
        el.style.maxWidth = random + "px";
        el.style.height = random + "px";
        el.style.margin = Math.random() * (7 - 3) + 3 + "px";
        el.style.alignSelf = "end";
    }

    let stylingBubblesImgBig = document.querySelectorAll('.team-bubble--img--big');
    for (let el of stylingBubblesImgBig) {
        let random = Math.random() * (250 - 150) + 120;
        el.style.minWidth = random + "px";
        el.style.maxWidth = random + "px";
        el.style.height = random + "px";
        el.style.margin = Math.random() * (7 - 3) + 3 + "px";
    }

    class Button {
        constructor(node) {
            this.button = node;
            this.distance = 100;
            this.a = 160;
            this.mouseHasEntered = false;
            this.mouseIsInButtonTerritory = false;
            this.init();
            this.handleEvent();
        }

        init() {
            let {
                width,
                height,
                x: centerPointX,
                y: centerPointY
            } = this.button.getBoundingClientRect(); // gives width, height, left-x,top-y of the button

            centerPointX = centerPointX + width / 2; //  center point of button on x-axis
            centerPointY = centerPointY + height / 2; //  center point of button on y-axis

            this.centerPointX = centerPointX;
            this.centerPointY = centerPointY;
        }

        handleEvent() {
            window.addEventListener('mousemove', (e) => this.handleMove(e));
            window.addEventListener('mouseout', () => this.handleReset())
            window.addEventListener('scroll', () => this.init()); //  updates the button x,y position // for the button below viewport
            buttonObjects.push({button: this.button, isHovered: this.mouseIsInButtonTerritory});
        }

        handleMove(e) {
            const x = e.x; // current x of cursor
            const y = e.y; // current y of cursor

            const leftBorderLine = this.centerPointX - this.distance;
            const rightBorderLine = this.centerPointX + this.distance;
            const topBorderLine = this.centerPointY - this.distance;
            const bottomBorderline = this.centerPointY + this.distance;
            this.xWalk = (x - this.centerPointX) / 2; // the distance to move the button when mouse moves on X axis
            this.yWalk = (y - this.centerPointY) / 2; // the distance to move the button when mouse moves on Y axis

            this.mouseIsInButtonTerritory =
                x > leftBorderLine &&
                x < rightBorderLine &&
                y > topBorderLine &&
                y < bottomBorderline; // becomes true if  mouse is inside all of these border-line


            if (this.mouseIsInButtonTerritory) {
                if (!this.mouseHasEntered) {
                    //  this must happen only once to create outside borderline
                    //  creating another level borderline by increasing distance;
                    //  while cursor is returning the button comes out of nearest border-line and return from this borderline
                    this.distance = 200;
                    this.mouseHasEntered = true;
                }
                this.handleCatch();   // when mouse enters the button's territory

            } else {
                this.handleReset()
            }

            const index = buttonObjects.findIndex(button => button.button === this.button);
            buttonObjects[index].isHovered = this.mouseIsInButtonTerritory;
        }

        handleCatch() {
            // translates the button in the direction where cursor is.
            this.button.style.transform = `translate(${this.xWalk}px, ${this.yWalk}px)`;
        }

        handleReset() {
            // resets the position of the button as it was initial.
            this.button.style.transform = `translate(${0}px, ${0}px)`;
            if (this.mouseHasEntered) this.distance = 200;
            this.mouseHasEntered = false; // when button is return to it's position (mouseHasEntered = true) lets to increase the initial borderline of button for the next time
        }
    }


    function handleBubble(e) {
        /*
        bubble.style.left = `${e.x}px`;
        bubble.style.top = `${e.y}px`;
        const hasAnyButtonHovered = buttonObjects.some(buttonObj => buttonObj.isHovered);
        if (hasAnyButtonHovered || e.target.classList.contains("nav__link")) {
            bubble.classList.add("bubble--big");
            document.body.style.cursor = '-webkit-grab';
        } else {
            bubble.classList.remove("bubble--big");
            document.body.style.cursor = 'auto';
        }
        */
    }


    const buttons = document.querySelectorAll('.magnet');
    const bubble = document.querySelector('.bubble');
    const buttonObjects = [];


    buttons.forEach(button => {
        const node = button.querySelector('.button__like-text');
        new Button(node);
    });

    window.addEventListener("mousemove", (e) => handleBubble(e));

}